.program-index {
  margin-bottom: -10px;

  &.program-index-custom {
    .customOrderFilters {

      .all-user {
        .search-bar {
          margin-bottom: 20px;

          .common-search-bar {
            .input-group {
              .form-control {
                font-size: 13px;
                height: 40px;

                &::placeholder {
                  font-size: 13px;
                }
              }

              .input-group-text {
                width: 40px;
                height: 40px;
                padding: 8px;
              }
            }
          }
        }
      }
    }
  }

  .page-title {
    padding: 0 !important;

    h1 {
      margin-bottom: 0;
    }

    .common-btn {
      font-size: 14px;
      line-height: 14px;
      padding: 12px 15px;
    }
  }

  // .all-user {
  //   span {
  //     padding: 9px;
  //   }
  //   .input-group {
  //     input {

  //     }
  //   }
  // }

  .input-group-text {
    padding: 4px 10px !important;
  }

  .MuiStepper-root {
    .MuiStep-root {
      button {
        padding: 10px 20px;
        margin: 0 -16px;

        &:first-child {
          padding: 10px 0;
          margin: 0;
        }
      }
    }
  }

  .common-title {
    margin: 10px -10px;

    h6 {
      margin-bottom: 0;
    }
  }

  .common-form {
    .form-program {
      span {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        margin: 0 0 8px 0;
        display: block;
      }

      .custom-file-upload {
        width: 190px;
        display: flex;
        flex-direction: column;
        margin-top: 28px;

        label {
          // background-color: #acacac1a;
          // border: 1px solid #acacac;
          border-radius: 10px;
          text-align: center;
          padding: 10px 18px;
          cursor: pointer;
          margin-bottom: 0;
        }

        input {
          text-align: center;
        }
      }

      .image-item {
        margin: 5px 0;
        position: relative;
        height: 200px;
        max-width: 360px;
        width: 100%;


        video {
          max-width: 350px;
          width: 100%;
          height: 200px;
          margin: 0 10px;

          .video-control {
            width: 100% !important;
          }
        }
      }

      .MultipleVideosInput {
        .btn-user-theme {
          width: 100%;
          border-radius: 10px;
        }
      }

      input {
        line-height: unset;
      }
    }

    .upload-video {
      display: flex;
      align-items: center;
      overflow: auto;

      .image-item {
        margin: 10px 12px 0 0;
        height: 127px;
        max-width: 165px;
        width: 100%;

        .img-table {
          max-width: 150px;
          height: 120px;
        }
      }
    }
  }

  .day-summery {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 40px;

    // @media (min-width: 1750px) {
    //   justify-content: space-between;
    // }
    button {
      max-width: 200px;
      width: 100%;
      margin-bottom: 12px;
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }

      &:hover,
      &:active {
        background-color: #c00000;
      }
    }

    .form-select {
      margin-bottom: 30px;
    }
  }

  .exercise-detail {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0;

    .common-search-bar {
      .input-group-text {
        padding: 0 10px;
        height: 30px;

        svg {
          width: 15px;
        }
      }

      .form-control {
        font-size: 12px;
        line-height: 12px;
        padding: 0 10px;
        min-height: 30px;

        &::placeholder {
          font-size: 12px;
          line-height: 12px;
          font-weight: 400;
        }
      }
    }

    .exercise-select {
      max-width: 185px;
      width: 100%;
      margin-right: 10px;
      margin-bottom: 12px;

      .select__menu {
        z-index: 99;
      }

      &:last-child {
        margin-right: 0;
      }

      .form-label {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 10px;
      }

      .form-control,
      .form-select,
      textarea {
        font-size: 12px;
        line-height: 12px;
        padding: 9px 5px;
        height: 30px;
      }

      .basic-multi-select {
        .select__control {
          font-size: 12px;
          line-height: 12px;
          padding: 0 5px;
          width: 185px;
          min-height: 30px;
        }
      }
    }

    .form-control {
      font-size: 12px;
      line-height: 12px;

      &::placeholder {
        font-size: 12px;
        line-height: 12px;
      }
    }

    .common-search-bar {
      margin-bottom: 10px;
      max-width: 375px;
      width: 100%;

      input {
        padding: 0 20px;
      }
    }

    .common-btn {
      font-size: 12px;
      line-height: 12px;
      padding: 8px 15px;

      svg {
        path {
          fill: transparent;
        }
      }
    }
  }

  .common-video {
    .exercise-video {
      .video-detail {
        width: 170px;
      }
    }

    .form-select {
      min-height: 20px;
    }

    .spin-loader {
      height: 510px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  .note {
    margin-top: 15px;

    label {
      font-size: 12px;
      line-height: 12px;
      color: #333333;
      margin-bottom: 5px;
      padding: 5px;
    }

    textarea {
      font-size: 12px;
      line-height: 12px;
      color: #acacac;
      height: 35px;
      padding: 10px 15px;
    }

    .basic-multi-select {
      .select__control {
        min-height: 35px;
      }
    }

    .common-btn {
      font-size: 12px;
      line-height: 12px;
      padding: 8px 15px;
      margin-top: 30px;
    }
  }

  // tests page
  .test-index {
    background: #fff;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 4px 0px #0000001a;
    border-radius: 10px;

    textarea {
      border: 3px solid #9f9c9c !important;
      min-height: 190px;
      border-radius: 20px;
      padding: 5px 10px;
    }

    h3 {
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #9f9c9c;
    }

    .gray-btn {
      background-color: #9f9c9c;
      border: 1px solid #9f9c9c;

      &:hover {
        color: white;
      }
    }

    .custom-file-upload {
      .common-btn {
        width: 100%;
      }
    }

    .image-item {
      position: relative;

      .img-table {
        width: 100%;
        // height: 200px;
      }

      video {
        width: 100%;
        height: 170px;
        border: none;

      }

      .btn-cross {
        position: absolute;
        top: 8px;
        right: 20px;
        z-index: 1;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: 0 -40px;
        background: #c00300;
        padding: 8px;
        border: none;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: white;
          }
        }
      }

      .common-btn {
        padding: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 25px;
          height: 25px;
        }
      }

      .image-item__btn-wrapper {
        .common-btn {
          svg {
            margin: 0px;
          }
        }
      }
    }

    .form-group-add-text {
      // max-height: 250px;
      overflow-y: auto;
      padding-right: 15px;

      .mb-4 {
        margin-bottom: 15px !important;
      }

      .wrapup {
        .form-control {
          font-size: 14px;
        }

        .wrapup-text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          .form-label {
            margin: 0px;
          }


          .btn-cross {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin: 0px;
            background: #c00300;
            padding: 8px;
            border: none;
          }
        }
      }
    }

    .video-horizantal-block {
      display: flex;
      margin: 0px -15px;
      // flex-wrap: wrap;
      overflow-y: auto;

      .video-horizantal-block-inner {
        padding: 0px 15px 15px;
        position: relative;

        .image-item {
          width: 365px;
        }

        .img-table {
          width: 100%;
          // height: 200px;
        }

        .btn-cross {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin: 0px;
          background: #c00300;
          padding: 8px;
          border: none;
          position: absolute;
          top: 57px;
          right: 22px;

          svg {
            width: 20px;
            height: 20px;

            path {
              fill: white;
            }
          }
        }
      }
    }

    .save-next-add-more {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  // tests page end
  // .add-new-program-new-block {
  //   .btn-flex-block {
  //     display: flex;
  //     align-items: flex-end;
  //     justify-content: flex-end;
  //     margin-top: 20px;
  //   }
  // }
}