.file-drop {
  width: 100%;
  height: fit-content;
  font-weight: bold;
  font-size: large;
}

.file-drop > .file-drop-target {
  transition: border-color 0.3s ease;
  border: 0.2em solid #000000;
  border-radius: 1em;
  text-align: center;
  padding: 3em 1em;
  cursor: pointer;
  font-size: 12px;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  border-color: #90ee90;
  transition: border-color 0.3s ease;
  color: #cacaca;
}

.hidden {
  display: none;
}

.toolBar {
  padding: 1em;
  margin: 2em;
  text-align: center;
  border-radius: 1em;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.toggle {
  display: none;
  background-color: rgb(185, 185, 185);
  color: yellow;
  height: 2em;
  width: 2em;
  text-align: center;
  line-height: 2em;
  vertical-align: middle;
  border-radius: 100em;
  font-size: x-large;
  z-index: 100;
  position: fixed;
  bottom: 1em;
  right: 1em;
  cursor: pointer;
  transition: background-color 0.2s ease;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.toggle:hover {
  transition: background-color 0.2s ease;
  background-color: rgb(146, 146, 146);
}

/* Styling for Editing */
.wrapper {
  width: 100%;
  max-width: 100%;
  padding: 1rem 1rem 1rem 0rem;
  border-radius: 4px;
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.video {
  width: 100%;
  cursor: pointer;
}

.playback {
  position: relative;
  margin-top: 1rem;
  margin-left: 4px;
  margin-right: 20px;
  height: 10px;
  background: #2f3b44;
  margin-bottom: 1rem;
  border-radius: 1em;
}

button.close-button {
  position: absolute;
  top: 3%;
  right: 2%;
  border: none;
  margin: 0;
  border-radius: 50%;
  background-color: #ec1246;
  padding: 0px 3px 1px;
  z-index: 999;
}
button.close-button svg {
  height: 20px;
  width: 20px;
  color: white;
}
button.close-button-temp {
  position: absolute;
  top: 3%;
  right: 2%;
  border: none;
  margin: 0;
  border-radius: 50%;
  background-color: #ec1246;
  padding: 6px 6px 6px 6px;
  z-index: 999;
}
button.close-button svg {
  height: 20px;
  width: 20px;
  color: white;
}
.playpausebtn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playpause {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #00000091;
  z-index: 1;
}
.playpause svg {
  z-index: 100;
}

.playpausebtn:hover .playpause {
  display: flex;
  /* z-index: 1; */
}

.playpause svg {
  color: white;
  font-size: 4rem;
}
.video-info {
  display: flex;
  justify-content: space-between;
}

.duration {
  text-align: right;
}

.trim-duration {
  text-align: left;
}
.center {
  text-align: center;
  display: flex;
  flex-direction: column;
}

label.duration-title {
  font-size: 14px;
  opacity: 0.6;
}
.playback .seekable {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(240, 240, 240);
  cursor: pointer;
}
.playback .grabber {
  position: absolute;
  top: -4px;
  bottom: -4px;
  width: 18px;
  border-radius: 2px;
  z-index: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.playback .grabber.start {
  background: #fc4242;
}
.playback .grabber.end {
  background: #fc4242;
}
.playback .grabber:hover {
  transform: scaleY(1.4);
}
.playback .grabber svg {
  /* user-drag: none;
    -moz-user-select: none;
    -webkit-user-drag: none; */
}
.playback .progress {
  background: #0072cf;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  pointer-events: none;
  height: 100%;
}

.controls {
  display: flex;
  justify-content: center;
  text-align: center;
}
.controls .player-controls button {
  margin-top: 10px !important;
  width: 34px;
  margin: 0 0.125rem;
}
.controls .player-controls .play-control {
  background: #ec1246;
  border: 0;
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.controls .player-controls .play-control:hover {
  background: #2f3b44;
}
.controls .player-controls .play-control:active {
  color: #ffffff;
}
.controls .player-controls .seek-start {
  background: #ec1246;
  border: 0;
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.controls .player-controls .seek-start:hover {
  background: #ec1246;
}
.controls .player-controls .seek-start:active {
  color: #ffffff;
}
.controls .player-controls .seek-end {
  background: #ec1246;
  border: 0;
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.controls .player-controls .seek-end:hover {
  background: #ec1246;
}
.controls .player-controls .seek-end:active {
  color: #ffffff;
}
.controls .settings-control {
  margin-top: 10px !important;
  background: #ec1246;
  border: 0;
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.controls .settings-control:active {
  color: #ffffff;
}

.controls .settings-control:hover {
  background: #445562;
}
.controls .trim-control {
  margin-top: 10px !important;
  background: #ec1246;
  border: 0;
  color: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.controls .trim-control:hover {
  background: #ec1246;
}

.margined {
  margin: 0em 0.3em;
}

.warning {
  padding: 0.8em;
  color: #da7f00;
  background-color: #ffecd1;
  border-radius: 0.3em;
  border: 0.1em solid #f0ad4e;
  margin: 1em;
  justify-content: center;
  text-align: center;
}

.thumbnail {
  width: 80%;
  height: auto;
  justify-content: center;
  align-items: center;
  border: 0.2em solid black;
  border-radius: 0.3em;
}

.marginVertical {
  margin: 1.5em 0em;
}
