.dashboard {
  .middal-section {
    margin-bottom: 40px;

    .fit-it-here {
      padding: 20px 10px 30px;
      background-color: white;
      box-shadow: 0px 4px 4px 0px #0000000d;
      border-radius: 10px;
      margin-bottom: 30px;

      @media (min-width: 1400px) {
        margin-bottom: 0;
      }

      .title {
        display: flex;
        border-bottom: 1px solid #acacac;
        justify-content: space-between;
        margin: 0 -10px;
        padding: 0 20px;
      }

      .fit-it-img {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;

        figure {
          width: 137px;
          height: 120px;
          margin: 10px;

          @media (min-width: 1600px) {
            width: 170px;
            height: auto;
          }

          @media (min-width: 1860px) {
            width: 137px;
            height: 120px;
          }

          img {
            width: 100%;
            height: 100%;
            border: 1px solid #525252;
            border-radius: 4px;
          }
        }
      }

      .fit-it-video {
        padding: 10px 10px 0;
        margin-top: 20px;
        position: relative;

        // &::after {
        //   content: "";
        //   background-image: url("../assets/images/icons/play-icon.svg");
        //   background-repeat: no-repeat;
        //   position: absolute;
        //   width: 40px;
        //   height: 40px;
        //   left: 48%;
        //   top: 47%;
        //   @media (min-width: 1400px) {
        //     left: 46%;
        //     top: 43%;
        //   }
        // }
        video {
          width: 100%;
          border: 1px solid #525252;
          border-radius: 10px;
          height: 100%;
          //   @media (min-width: 1400px) {
          //     height: 255px;
          //   }
        }
      }
    }
  }

  .last-section {
    height: 840px;
    overflow: auto;

    .user-review {
      padding: 30px 30px 30px 20px;
      box-shadow: 0px 4px 4px 0px #0000000d;
      background-color: white;
      border-radius: 10px;

      h6 {
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
        color: #333333;
        margin-bottom: 30px;
      }

      .user-review-box {
        border-top: 1px solid #acacac;
        margin: 0 -30px;
        padding: 30px;

        &:last-child {
          padding-bottom: 0;
        }

        .user-title {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .user-img {
            display: flex;

            figure {
              width: 50px;
              height: 50px;
              margin: 0 10px 20px 0;
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }

            .user-name {
              h5 {
                font-size: 20px;
                font-weight: 600;
                line-height: 20px;
                color: #333333;
              }

              p {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                color: #acacac;
              }
            }
          }

          .review-icon {
            display: flex;
            align-items: center;

            i {
              margin-right: 4px;
            }
          }
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        color: #acacac;
        margin-bottom: 0;
      }
    }
  }

  // .change-password {
  //   .common-form {}
  // }

  .notification-index {
    box-shadow: 0px 4px 4px 0px #0000000d;
    background-color: white;
    border-radius: 10px;
    padding: 20px;

    .sc-hmdomO {
      justify-content: flex-start;
      border-bottom: 1px solid rgba(172, 172, 172, 0.2);
      padding-bottom: 20px;

      > div {
        padding: 0 !important;
      }
    }

    .notification-list {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(172, 172, 172, 0.2);
      margin: 0 -20px;
      padding: 30px 0;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      i {
        background-color: rgba(192, 0, 0, 0.1);
        max-width: 90px;
        width: 100%;
        height: 90px;
        display: flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        margin: 0 20px;

        svg {
          width: 45px;
          height: 45px;
        }
      }

      .notification-detail {
        margin-right: 20px;
        width: 100%;

        .notification-title {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .notification-date {
            margin-bottom: 10px;
          }
        }
      }
    }

    p {
      color: rgba(172, 172, 172, 1);
    }
  }
}

.dashboard-main {
  .dashboard-main-inner {
    .dashboard-main-inner-block {
      padding: 30px 0px 10px;
      border-bottom: 1px solid #dee2e6;

      &.diff-width-block {
        padding: 30px 0px;
      }

      h2 {
        color: #c00000;
        font-size: 20px;
        line-height: 22px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .total-user-block {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0px 15px;

        h4 {
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          margin-bottom: 0px;

          &:nth-child(2) {
            color: #0d6efd;
          }
        }
      }

      &.diff-width-block {
        .flex-block-dashboard {
          .dashboard-main-inner-block-flex {
            &:nth-child(1),
            &:nth-child(3) {
              width: 25%;
            }

            &:nth-child(2) {
              width: 50%;
            }
          }
        }
      }

      &.diff-libraries-manage {
        padding: 30px 0px;

        .flex-block-dashboard {
          .dashboard-main-inner-block-flex {
            .dashboard-main-inner-block-flex-two {
              width: 100%;
            }

            &:nth-child(1) {
              width: 25%;
            }

            &:nth-child(2) {
              width: 25%;
              border-right: 1px solid #dee2e6;

              .two-block-chart {
                width: 100%;
              }
            }

            &:nth-child(3) {
              width: 50%;
            }
          }
        }
      }

      &.diff-programe-manage {
        padding: 30px 0px;

        .flex-block-dashboard {
          .dashboard-main-inner-block-flex {
            .dashboard-main-inner-block-flex-two {
              width: 100%;
            }

            &:nth-child(1) {
              width: 30%;
            }

            &:nth-child(2) {
              width: 30%;
              border-right: 1px solid #dee2e6;

              .two-block-chart {
                width: 100%;
              }
            }

            &:nth-child(3) {
              width: 40%;
            }
          }
        }
      }

      &.diff-recommdend-manage {
        padding: 30px 0px;
        border: none;

        .flex-block-dashboard {
          .dashboard-main-inner-block-flex {
            .dashboard-main-inner-block-flex-two {
              width: 100%;
            }

            &:nth-child(1) {
              width: 33.33%;
            }

            &:nth-child(2) {
              width: 33.33%;
              border-right: 1px solid #dee2e6;

              .two-block-chart {
                width: 100%;
              }
            }

            &:nth-child(3) {
              width: 33.33%;
            }
          }
        }
      }
    }

    .flex-block-dashboard {
      display: flex;
      margin: 0 -15px;

      .dashboard-main-inner-block-flex {
        width: 33.33%;
        padding: 0px 15px;
        display: flex;

        .dashboard-main-inner-block-flex-two {
          width: 50%;
          padding: 0px 15px;

          &.margin-top-diff {
            margin-top: 36px;
          }

          &.full-width {
            width: 100%;
          }

          .table-flex {
            .table-flex-inner {
              margin-bottom: 25px;

              h3 {
                padding: 10px;
                text-align: center;
                background: #000;
                font-size: 17px;
                color: #fff;
                font-weight: 600;
                margin: 0px;

                &.red-text {
                  background: #c00000;
                }
              }

              table {
                width: 100%;
                border: 1px solid #acacac;
                th {
                  width: 60%;
                  &:nth-child(2) {
                    width: 40%;
                  }
                }

                td {
                  padding: 5px;
                  font-size: 13px;
                  border: 1px solid #acacac;
                  vertical-align: middle;
                  &:nth-child(1) {
                    text-overflow: ellipsis;
                    max-height: 45px;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }

              .table-flex-inner-inner {
                &:nth-child(1) {
                  margin-bottom: 25px;
                }
              }
            }

            .read-more-link {
              margin-top: 10px;
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;

              a {
                font-size: 14px;
                line-height: 14px;
                font-weight: 500;
                margin-bottom: 0px;
                color: #0d6efd;
                text-decoration: none;
              }
              div {
                font-size: 14px;
                line-height: 14px;
                font-weight: 500;
                margin-bottom: 0px;
                color: #0d6efd;
                cursor: pointer;
              }
            }
          }
        }

        .right-side-value {
          text-align: right;
        }

        .two-block-chart {
          width: 50%;
          padding: 0px 15px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &.full-width {
            width: 100%;
          }

          &.d-block-chart {
            display: block;
          }

          > h3 {
            color: #000;
            font-size: 18px;
            line-height: 18px;
            font-weight: 600;
            margin-bottom: 20px;
            text-align: center;

            &.red-text {
              color: #c00000;
            }
          }

          .MuiChartsLegend-root {
            position: relative;
            top: 50px;
          }
        }
      }
    }
  }
  .red-text {
    color: #c00000;
  }
  .top-section {
    .dash-title-box {
      // box-shadow: 0px 4px 4px 0px #0000000d;
      // background-color: white;
      display: flex;
      border-radius: 10px;
      margin-bottom: 30px;
      border: none;
      padding: 23px;

      .card-outline {
        margin-right: 20px;

        span {
          width: 90px;
          height: 90px;
          display: flex;
          border-radius: 10px;
          align-items: center;
          justify-content: center;
          background: rgba(192, 0, 0, 0.1);
          box-shadow: 0px 4px 4px 0px #0000000d;

          svg {
            width: 60px;
            height: 60px;

            path {
              fill: #c00000;
            }
          }

          .tr-point {
            path {
              fill: transparent;
              stroke: #c00000;
            }
          }
          .program-icon {
            path {
              fill: #c00000;
              stroke: #c00000;
            }
          }
        }

        .card-block {
          h2 {
            font-size: 50px;
            font-weight: 500;
            line-height: 50px;
            color: #525252;
          }

          h6 {
            font-size: 20px;
            font-weight: 500;
            line-height: 20px;
            color: #333333;
          }
        }
      }

      // .linkStats {
      //   text-decoration: none;

      // }
    }
  }
}
