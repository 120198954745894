.trigger-Point-index {
  h1 {
    margin-bottom: 0;
  }

  // common css
  .custom-file-upload {
    margin-bottom: 20px;
  }

  .img-thumbnail {
    height: 150px;

    @media (min-width: 1700px) {
      width: 100%;
      height: 210px;
      padding: 3px;
    }
  }

  .image-item {
    height: 150px;

    @media (min-width: 1700px) {
      height: 200px;
    }

    video {
      height: 150px;
      // border: none;

      @media (min-width: 1700px) {
        height: 200px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    button {
      right: 0;
      top: 0;
    }
  }

  textarea {
    height: 40px;
  }

  .select__control {
    min-height: 40px !important;
  }

  .common-btn {
    font-size: 14px;
  }

  .common-input {
    margin-bottom: 30px;
  }

  .third-img {
    max-width: 235px;
    border: 1px solid #dee2e6;
    min-height: 150px;
    border-radius: 5px;

    @media (min-width: 1700px) {
      min-height: 200px;
    }
  }

  // common css end

  .add-trigger-Point {
    .common-input {
      margin-bottom: 30px;
    }
  }

  .third-img {
    max-width: 235px;
    border: 1px solid #dee2e6;
    min-height: 150px;
    border-radius: 5px;

    @media (min-width: 1700px) {
      min-height: 200px;
    }
  }

  .video-box {
    max-width: 500px;
    border: 1px solid #dee2e6;
    min-height: 150px;
    border-radius: 5px;

    @media (min-width: 1700px) {
      min-height: 200px;
    }
  }
}
