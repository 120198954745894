.equipment-index {
  .common-form {
    .form-select {
      border: 1px solid #acacac !important;
    }

    .image-item {
      max-width: 350px;
      width: 100%;
      height: 250px;
      margin-bottom: 20px;
    }

    .image-item-logo {
      width: 200px !important;
      height: 100px !important;
      margin-bottom: 5px;
      // margin-top: 5px;
      flex-direction: row;
      margin-right: 20px;
    }
    .image-thumbnail-logo {
      width: 100% !important;
      height: 100% !important;
      padding: 5px;
      border: 1px solid #dee2e6;
      border-radius: 5px;
    }
    .delete-logo-img {
      background: "#c13033";
      border-radius: 0;
      border: 1px solid transparent;
      transition: all 0.5s ease;
      color: #fff;
      font-size: 13px;
      height: 40px;
      width: 40px;
      margin-left: 5px;
      margin-right: 30px;
      margin-top: 28px;
      // align-self: center;
    }
  }
}
