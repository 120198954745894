/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 1, 2021 */

/* Mulish font */
@font-face {
  font-family: "Mulish";
  src: url("Mulish/Mulish-ExtraLight.woff2") format("woff2"),
    url("Mulish/Mulish-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Mulish";
  src: url("Mulish/Mulish-Light.woff2") format("woff2"),
    url("Mulish/Mulish-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Mulish";
  src: url("Mulish/Mulish-Regular.woff2") format("woff2"),
    url("Mulish/Mulish-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Mulish";
  src: url("Mulish/Mulish-Medium.woff2") format("woff2"),
    url("Mulish/Mulish-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Mulish";
  src: url("Mulish/Mulish-SemiBold.woff2") format("woff2"),
    url("Mulish/Mulish-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Mulish";
  src: url("Mulish/Mulish-Bold.woff2") format("woff2"),
    url("Mulish/Mulish-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mulish";
  src: url("Mulish/Mulish-ExtraBold.woff2") format("woff2"),
    url("Mulish/Mulish-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Mulish";
  src: url("Mulish/Mulish-Black.woff2") format("woff2"),
    url("Mulish/Mulish-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
/* Mulish font */

/* Nunito font */
@font-face {
  font-family: "Nunito";
  src: url("Nunito/Nunito-ExtraLight.woff2") format("woff2"),
    url("Nunito/Nunito-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  src: url("Nunito/Nunito-Light.woff2") format("woff2"),
    url("Nunito/Nunito-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  src: url("Nunito/Nunito-Regular.woff2") format("woff2"),
    url("Nunito/Nunito-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  src: url("Nunito/Nunito-Medium.woff2") format("woff2"),
    url("Nunito/Nunito-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  src: url("Nunito/Nunito-SemiBold.woff2") format("woff2"),
    url("Nunito/Nunito-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  src: url("Nunito/Nunito-Bold.woff2") format("woff2"),
    url("Nunito/Nunito-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("Nunito/Nunito-ExtraBold.woff2") format("woff2"),
    url("Nunito/Nunito-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  src: url("Nunito/Nunito-Black.woff2") format("woff2"),
    url("Nunito/Nunito-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
/* Nunito end */
