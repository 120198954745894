.muscles-index {
  h1 {
    margin-bottom: 0;
  }

  h6 {
    margin-top: 25px;
  }

  .select__control {
    min-height: 40px !important;
  }

  // .custom-file-upload {
  //   margin-bottom: 20px;
  // }

  textarea {
    height: 40px;
  }

  .all-user {
    .search-bar {
      margin-bottom: 12px;

      span {
        padding: 2px 10px;

        svg {
          width: 20px;
          height: auto;
        }
      }


    }
  }

  .third-img {
    max-width: 235px;
    border: 1px solid #dee2e6;
    min-height: 150px;
    border-radius: 5px;

    @media (min-width: 1700px) {
      min-height: 200px;
    }
  }

  .img-thumbnail {
    width: 100%;
    height: 210px;
    padding: 3px;
  }

  .video-box {
    max-width: 500px;
    border: 1px solid #dee2e6;
    min-height: 150px;
    border-radius: 5px;

    @media (min-width: 1700px) {
      min-height: 200px;
    }
  }

  .image-item {
    height: 150px;

    @media (min-width: 1700px) {
      height: 200px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    video {
      height: 150px;

      @media (min-width: 1700px) {
        height: 210px;
      }

    }

    button {
      right: 0;
      top: 0;
    }
  }



  .add-muscles {
    .custom-file-upload {
      margin-bottom: 20px;
    }
  }

  .edit-muscles {
    .custom-file-upload {
      margin-bottom: 0;
    }
  }
}