.header-index {
  background: white;
  box-shadow: 0px 4px 4px 0px #0000001a;
  width: calc(100% - 300px);
  margin-left: auto;
  padding: 0 20px 0 30px;
  width: 100%;
  .navbar {
    .navbar-toggler {
      display: block;
    }
    .navbar-nav {
      .notificationsDropdown {
        position: relative;
        &::after {
          content: "2";
          background-color: #c00000;
          position: absolute;
          border-radius: 50%;
          font-size: 12px;
          color: white;
          right: 5px;
          top: 0;
          width: 15px;
          height: 15px;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .dropdown-toggle {
          &:after {
            display: none;
          }
        }
      }
      .profile-name {
        display: flex;
        align-items: center;
        .profile-icon {
          margin-right: 10px;
          svg {
            width: 40px;
            height: 40px;
          }
        }
        p {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          color: #333333;
        }
        .dropdown-toggle {
          &:after {
            display: none;
          }
        }
        .nav-item {
          &::after {
            content: "";
            background-image: url("../assets/images/icons/red-arrow.svg");
            background-repeat: no-repeat;
            transform: rotate(180deg);
            position: absolute;
            display: block;
            height: 10px;
            width: 10px;
            top: 5px;
          }
        }
      }
    }
  }
}
