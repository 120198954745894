.exercise-menagement {
  // background-color: transparent;
  // .MuiDataGrid-columnHeaders {
  //   .MuiDataGrid-columnHeadersInner {
  //     .mui-yrdy0g-MuiDataGrid-columnHeaderRow {
  //       .MuiDataGrid-columnHeader {
  //         &:nth-child(2) {
  //           width: 163px !important;
  //           min-width: 163px !important;
  //           max-width: 163px !important;
  //         }
  //       }
  //     }
  //   }
  // }

  h1 {
    margin-bottom: 12px;
  }

  .page-title {
    h1 {
      margin-bottom: 0;
    }
  }

  .exercise-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    .nav-tabs {
      border-bottom: unset;
      flex-wrap: nowrap;
      align-items: center;
      margin-right: 10px;

      @media (min-width: 1850px) {
        width: 40%;
      }

      .nav-item {
        border-radius: 10px;
        margin-right: 12px;
        width: 170px;
        border: 1px solid #acacac80;

        &:last-child {
          margin-right: 0;
        }

        .nav-link {
          color: black;
          border: none;
          text-align: center;
          // width: 100%;
          width: 170px;
          padding: 10.25px;
          transition: all 0.5s;
          white-space: nowrap;
          font-size: 13px;

          &:hover {
            border: none;
            transition: all 0.5s;
          }

          &.active {
            background-color: #c00000 !important;
            border: 1px solid #c00000 !important;
            border-radius: 10px;
            padding: 9.25px 10px;
            width: 100%;
            transition: all 0.5s;
          }
        }
      }
    }

    .common-add {
      margin-bottom: 0;
      width: 55%;

      .all-user {
        width: 62%;

        .select-all {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          @media (min-width: 767px) {
            margin-bottom: 30px;
          }

          label {
            margin: 0 0 0 10px;
          }
        }

        .form-control {
          padding: 15px;
        }

        .search-bar {
          margin-bottom: 0;

          .common-search-bar {
            margin-right: 0;

            .input-group {
              .input-group-text {
                padding: 0 10px;

                svg {
                  width: 20px;
                }
              }

              .form-control {
                font-size: 13px;
                line-height: 18px;
                padding: 8px;

                &::placeholder {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }

      .diff-btn-custom {
        font-size: 13px;
      }
    }
  }

  .common-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .common-form {
    .form-control {
      height: 40px;
    }
  }

  .common-select {
    margin-bottom: 20px;

    .form-control {
      padding: 10px 12px;
    }
  }

  .theme-dataGrid {
    margin-top: 20px;
  }

  .form-control,
  .select__control,
  .form-select {
    height: 40px;
  }
}

.feet-here-index {
  .search-bar {
    display: flex;
    align-items: center;
    width: 100%;

    @media (min-width: 1200px) {
      justify-content: flex-end;
    }

    .common-search-bar {
      margin-right: 16px;
    }
  }

  .image-item {
    height: 255px;
    margin-bottom: 20px;
  }
}

.exercise-types-index {
}

.body-types-index {
}

.sub-category-index {
}

.sports-index {
  .sports-detail {
    margin-bottom: 40px;

    @media (min-width: 1500px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h1 {
      margin: 0 0 20px 0;

      @media (min-width: 1500px) {
        margin: 0;
      }
    }

    .search-bar {
      display: block;
      margin-bottom: 0;

      @media (min-width: 1500px) {
        display: flex;
      }

      .common-search-bar {
        margin: 0 20px 20px 0;

        @media (min-width: 1500px) {
          display: flex;
          margin: 0 20px 0 0;
        }

        .input-group {
          min-width: 600px;
          width: 100%;
        }
      }
    }

    .sports-select {
      margin: 0 0 20px 0;

      @media (min-width: 1500px) {
        margin: 0 20px 0 0;
      }

      select {
        min-width: 300px;
        width: 100%;
      }
    }
  }
}

.add-exercise {
  margin-bottom: -10px;

  .spin-loader {
    height: 510px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .form-label {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 10px;
  }

  .form-select {
    font-size: 12px;
    line-height: 12px;
    border: 1px solid #acacac !important;
    height: 25px;
    padding: 9px 20px;
    margin-bottom: 0;
  }

  .select__control {
    // width: 175px;
    font-size: 12px;
    line-height: 12px;
    min-height: 35px !important;
  }

  .common-title {
    margin: 10px -10px;

    h6 {
      margin-bottom: 0;
    }
  }

  .common-btn {
    font-size: 12px;
    line-height: 12px;
    padding: 10.5px 15px;
  }

  .page-title {
    padding: 0 !important;
    margin-bottom: 10px;

    h1 {
      margin-bottom: 0;
    }
  }

  .exercise-detail {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 1860px) {
      align-items: center;
    }

    .exercise-select {
      max-width: 180px;
      width: 100%;
      margin: 0 15px 15px 0;

      .form-select {
        min-height: 35px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .search-bar {
      margin-bottom: 16px;

      .common-search-bar {
        .input-group-text {
          padding: 5px 10px;

          svg {
            width: 20px;
          }
        }

        .form-control {
          font-size: 12px;
          line-height: 12px;
          padding: 0 10px;
          min-height: 35px;

          &::placeholder {
            font-size: 12px;
            line-height: 12px;
            font-weight: 400;
          }
        }
      }
    }

    .common-btn {
      svg {
        path {
          fill: transparent;
        }
      }
    }
  }

  .exercise-video {
    min-height: 500px;

    .video-detail {
      // width: 200px !important;
      video {
        height: 100px !important;

        @media (min-width: 1500px) {
          height: 120px !important;
        }
      }
    }

    .video-index {
      .main-sec {
        margin-bottom: 0 !important;
      }
    }
  }

  .note {
    padding: 15px 0;

    label {
      color: #333333;
    }

    textarea {
      font-size: 12px;
      line-height: 12px;
      height: 35px !important;
      color: #acacac;
      padding: 10px 20px;
    }

    .form-select {
      min-height: 35px;
    }

    .common-btn {
      margin-top: 28px;
    }
  }

  .right-video {
    .exercise-video {
      height: 510px;

      .drag {
        height: 110px;

        .wrapper {
          .VideoInput {
            .vidContainer {
              margin-bottom: 5px !important;

              video {
                height: 110px !important;
              }
            }
          }
        }
      }
    }
  }
}

.edit-exercise {
  .exercise-detail {
    .exercise-select {
      margin: 0 15px 5px 0;
    }

    .search-bar {
      margin-top: 30px;
      margin-bottom: 0;

      @media (min-width: 1860px) {
        margin-top: 20px;
      }
    }

    .common-btn {
      margin-top: 0;

      @media (min-width: 1387px) {
        margin-top: 30px;
      }

      @media (min-width: 1860px) {
        margin-top: 20px;
      }
    }
  }

  .exercise-video {
    min-height: 520px;

    .video-detail {
      video {
        min-height: 118px;
      }
    }

    .video-index {
      .video-player {
        video {
          min-height: 115px;
        }
      }

      .main-sec {
        margin-bottom: 20px !important;

        .drag {
          height: 115px;
        }
      }
    }

    .drag {
      height: 120px;

      video {
        height: 120px;
      }
    }
  }

  .spin-loader {
    height: 520px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.add-new-exercise {
  .common-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.exercise-step-2 {
  .page-title {
    h1 {
      font-size: 30px;
      font-weight: 500;
      line-height: 28px;
    }
  }

  .common-title {
    padding-bottom: 0;
    margin: 0 -20px 20px;

    h6 {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 30px;
    }
  }

  .form-check {
    .form-check-input {
      width: 20px;
      height: 20px;
      border: 1px solid #acacac;
      background-color: transparent;
      border-radius: 4px;

      &:checked {
        background-color: #c00000;
        border: 1px solid #c00000;
      }

      &:focus {
        border-color: #c00000;
        box-shadow: 0 0 0 0.25rem rgba(192, 0, 0, 0.25);
      }
    }

    .form-check-label {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #acacac;
      margin-right: 10px;
    }
  }

  .vidContainer,
  .image-item__btn-wrapper {
    // position: relative;

    button {
      // display: flex;
      // position: absolute;
      // left: 3px;
      // padding: 5px;
      // top: 3px;
      // border-radius: 5px !important;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .common-input {
    margin-top: 15px;
  }

  .form-select {
    margin-bottom: 0;
  }

  .common-select {
    margin-bottom: 30px;
  }

  .wrapper {
    padding: 1rem 0;
  }

  .delete-icon {
    button {
      padding: 5px;
    }
  }
}
