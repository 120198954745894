@import "header";
@import "manage-user";
@import "sidebar";
@import "variable";
@import "exercise.scss";
@import "program.scss";
@import "dashboard";
@import "profile";
@import "muscles";
@import "trigger-Point";
@import "equipment";

body {
  // font-family: "Nunito", sans-serif;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
}

.page-index {
  display: flex;
  // .page-title {
  //   padding: 20px 20px 0;
  // }
}

main {
  width: calc(100% - 300px);
  height: 100vh;
  overflow: auto;
}

.main-open {
  width: calc(100% - 300px);
}

.main-close {
  width: 100%;
}

.main_content_wrapper {
  background-color: #ebebeb4d;
  padding: 20px;
  // height: calc(100% - 62px);
}

.form-control,
.form-select,
textarea,
.select__control {
  font-size: 14px;
  line-height: 15px;
  font-weight: 400;
  padding: 0 10px;
  min-height: 40px;
  // color: #acacac;
  color: #000000;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #acacac !important;
  border-radius: 10px;

  &:hover,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #acacac;
  }
}

.form-select {
  color: #525252;
  height: 40px;
  // border: 1px solid #525252 !important;
  box-shadow: 0px 4px 4px 0px #0000000d !important;
  margin-bottom: 15px;
  -webkit-appearance: none;
}

textarea {
  padding: 5px 10px !important;
}

.mui-1jqq78o-placeholder {
  font-family: "Mulish";
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: #acacac;
}

h1 {
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 20px;
}

.form-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #525252;
  margin-bottom: 10px;
}

.common-add {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 15px;
  // @media (min-width: 1400px) {
  //   margin-bottom: 30px;
  // }

  .common-btn {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.btn {
  --bs-btn-padding-x: 0;
  --bs-btn-padding-y: 0;

  &:focus-visible {
    --bs-btn-hover-bg: transparent;
    --bs-btn-focus-box-shadow: unset;
    --bs-btn-hover-border-color: #c00000;
    --bs-btn-hover-color: #c00000;
  }

  &:disabled {
    --bs-btn-disabled-bg: transparent;
    --bs-btn-focus-box-shadow: unset;
    --bs-btn-disabled-border-color: #c00000;
    color: #c00000;
    border: 1px solid #c00000;
    --bs-btn-disabled-opacity: 1;
  }
}

.common-btn {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  border-radius: 10px;
  padding: 10px 15px;
  border: 1px solid #c00000;
  background-color: #c00000;
  color: white;
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.5s ease;

  &:hover,
  &:active {
    --bs-btn-active-border-color: 1px solid #c00000;
    --bs-btn-hover-border-color: 1px solid #c00000;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-color: #c00000;
    --bs-btn-hover-color: #c00000;
    --bs-btn-hover-bg: transparent;
    transition: all 0.5s ease;

    svg {
      path {
        fill: #c00000;
      }
    }
  }

  svg {
    path {
      fill: white;
    }
  }
}

$theme-red: #c00000;
$link-hover-white: #f9f9f9;

.common-delete-btn {
  background: $theme-red;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: all 0.5s ease;
  color: #fff;
  font-size: 13px;
  height: 40px;
  width: 40px;

  svg {
    width: 15px;
    height: 12px;
    transform: translateY(-1px);

    path {
      fill: $link-hover-white;
    }
  }

  &:hover,
  &:focus,
  &.active {
    svg {
      path {
        fill: $theme-red;
      }
    }

    background: transparent;
    color: $theme-red;
    border: 1px solid $theme-red;
    transition: all 0.5s ease;
  }

  &.active {

    &:hover,
    &:focus {
      background: $theme-red;
      color: $link-hover-white;
      border: 1px solid transparent;
      transition: all 0.5s ease;
    }
  }
}

.common-outline-btn {
  background-color: transparent;
  border: 1px solid #acacac;
  color: #acacac;
  border-radius: 10px;
  padding: 7px;
}

.page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h1 {
    margin-bottom: 0;
  }
}

.common-title {
  display: flex;
  margin: 0 -20px;
  padding: 0 20px 10px;
  border-bottom: 1px solid #acacac;
}

.disabled-btn {
  border: 1px solid #acacac !important;
  background-color: transparent !important;
  color: #acacac !important;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  @media (min-width: 1200px) {
    display: flex;
    flex-wrap: unset;
  }

  .select-all {
    display: flex;
    align-items: center;
    margin: 0 15px 15px 0;

    @media (min-width: 1200px) {
      margin: 0 30px 0 0;
    }

    .common-btn {
      white-space: nowrap;
    }
  }

  svg {
    path {
      fill: white;
    }
  }
}

.common-search-bar {
  width: 100%;
  margin-right: 20px;

  .input-group-text {
    background-color: #c00000;
    border: none;
    padding: 9px;

    svg {
      width: 20px;
      height: auto;

      path {
        fill: white;
      }
    }

    .form-control {
      width: 350px;
    }
  }

  .form-control {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    padding: 8px 10px;
    color: #acacac;
    flex-wrap: unset;

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      color: #acacac;
    }
  }
}

.base-Popper-root {
  .MuiPaper-root {
    ul {
      display: grid;

      li {
        padding: 3px 12px;
      }
    }
  }
}

.btn-sm {
  border: none;
  border-radius: 10px;
  padding: 5px;
  margin: -2px;

  svg {
    fill: white;
    width: 20px;
    height: 20px;
  }
}

.btn-danger {
  background-color: #c00000;
  margin-left: 13px;
}

.btn-info {
  background-color: #2e52b2;
}

.btn-warning {
  background: #ffad01;
}

// common filter end
.common-table {
  background-color: white;
  position: relative;
  box-shadow: 0px 4px 4px 0px #0000000d;
  border-radius: 10px;
}

.user-pagination {
  padding: 15px;

  .MuiStack-root {
    justify-content: flex-end;

    .MuiPagination-root {
      ul {
        display: flex;
        justify-content: center;

        li {
          .MuiButtonBase-root {
            background: #acacac66;
          }

          .Mui-selected {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: white;
            background-color: #c00000;
          }
        }
      }
    }

    .MuiSelect-select {
      padding: 5px 30px 5px 10px;
    }
  }
}

.delete-icon {
  padding: 5px 10px;
  // position: absolute;
  // z-index: 1;
  // left: -17px;
  // top: -58px;

  .nav-link,
  button {
    max-width: 40px;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    border-radius: 7px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.delete-icon-temp {
  padding: 5px 0px;
  // position: absolute;
  // z-index: 1;
  // left: -17px;
  // top: -58px;

  .nav-link,
  button {
    max-width: 40px;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    border-radius: 7px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.common-video {
  box-shadow: 0px 4px 4px 0px #0000000d;
  background-color: white;
  border-radius: 10px;
  padding: 20px 6px;

  @media (min-width: 1200px) {
    padding: 10px;
  }

  .page-title {
    margin-bottom: 30px;

    h6 {
      margin: 0px;
    }
  }

  .exercise-video {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 510px;
    overflow-y: auto;

    &.exercise-video-flex-custom {
      margin: 0px -7px;

      .video-detail {
        width: 25% !important;
        padding: 0px 7px;
        margin: 0px;

        .vid-thumbnail {
          .vid-title {
            margin-bottom: 7px;
          }
        }
      }
    }

    .video-detail {
      width: 170px;
      height: auto;
      margin: 0 5px;

      .vid-thumbnail {
        .poster-video-player {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          .poster-video-container {
            position: relative;

            &::after {
              content: "";
              // background-image: url("../assets/images/icons/play.svg");
              width: 30px;
              height: 30px;
              position: absolute;
              top: 35%;
              left: 40%;
              right: 0;
            }

            video {
              border-radius: 10px;
              height: 105px;
              background: linear-gradient(0deg,
                  rgba(0, 0, 0, 0.72) 0%,
                  rgba(255, 255, 255, 0) 110.83%);

              @media (min-width: 1500px) {
                height: 120px;
              }
            }
          }

          .controls {
            display: none;
            // display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 44px;
            background: linear-gradient(0deg,
                rgb(0, 0, 0) 0%,
                rgb(72, 71, 71) 31%,
                rgba(255, 255, 255, 0) 100%);
            padding: 0 10px;
            box-sizing: border-box;
            border-radius: 0 0 10px 10px;

            button {
              background-color: transparent;
              border: none;
              cursor: pointer;
              padding: 0;
              margin-right: 10px;

              &:focus {
                outline: none;
              }

              svg {
                height: 2em;
                width: 2em;
                color: #ffffff;
              }
            }
          }
        }

        .vid-title {
          margin-bottom: 15px;

          a {
            font-size: 12px;
            line-height: 12px;
            font-weight: 400;
            color: #333333;
            text-decoration: none;

            &:hover,
            &:active {
              color: #c00000;
            }
          }
        }
      }
    }
  }

  .thumbnail-gallery {

    // height: 570px;
    &.common-thumbnail-gallery {
      display: block;

      .video-index {

        // margin: 0px -7px;
        .main-sec {
          width: 25%;
          margin: 0px;
          padding: 0px 7px;
          height: 165px;
          margin-bottom: 3px !important;

          .drag {
            .wrapper {
              .VideoInput {
                width: 100%;

                .vidContainer {
                  width: 100%;

                  .video-player {
                    width: 100%;

                    video {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .video-index {
      display: flex;
      // flex-wrap: wrap;
      justify-content: center;

      .main-sec {
        position: relative;
        margin: 0 5px 0 0;

        // width: 70px;
        &:last-child {
          margin: 0 0 0 0;
        }

        // @media (min-width: 1200px) {
        //   width: 92px;
        // }
        // @media (min-width: 1400px) {
        //   margin: 0 10px 0 0;
        //   width: 114px;
        // }
        // @media (min-width: 1600px) {
        //   width: 140px;
        // }
        @media (min-width: 1900px) {
          margin: 0 15px 0 0;
          width: 170px;
        }

        .form-select {
          height: 20px;
          width: 112px;
          position: absolute;
          bottom: -12px;

          @media (min-width: 1500px) {
            width: 130px;
          }
        }

        .page-title {
          margin-bottom: 10px;

          h5 {
            font-size: 12px;
            line-height: 12px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 0;
          }
        }

        .drag {
          position: relative;
          text-align: center;
          min-height: 90px;
          // @media (min-width: 1200px) {
          //   min-height: 92px;
          // }
          // @media (min-width: 1400px) {
          //   min-height: 114px;
          // }
          // @media (min-width: 1600px) {
          //   min-height: 140px;
          // }

          .page-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            h5 {
              margin-bottom: 0;
            }

            i {
              background-color: #c00;
              border-radius: 50%;
              padding: 0;
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                width: 11px;
                height: 11px;
              }
            }
          }

          .wrapper {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-around;
            color: #bbb;
            background-color: white;
            box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.4196078431);
            max-height: 100%;

            ul {
              width: 100%;
              padding: 0;
              list-style-type: none;
              margin: 0;
              background-color: rgba(0, 0, 0, 0.2);
              box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                0 3px 1px -2px rgba(0, 0, 0, 0.2),
                0 1px 5px 0 rgba(0, 0, 0, 0.12);

              li {
                text-align: center;
                transition: 0.3s ease-out;
                margin-bottom: 15px;
                width: calc(50% - 30px);
                float: left;
                margin: 15px;
                border-radius: 3px;
                cursor: move;

                &:hover {
                  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
                    0 10px 10px rgba(0, 0, 0, 0.22);
                }
              }
            }

            .VideoInput {
              .vidContainer {
                // margin-bottom: 3px;
                position: relative;

                .video-player {
                  video {
                    border: 2px solid #c00000;
                    border-radius: 5px;
                    width: 175px;
                    height: 90px;
                    // width: 70px;
                    // min-height: 70px;
                    // @media (min-width: 1200px) {
                    //   width: 92px;
                    //   min-height: 92px;
                    // }
                    // @media (min-width: 1400px) {
                    //   width: 114px;
                    //   min-height: 114px;
                    // }
                    // @media (min-width: 1600px) {
                    //   width: 140px;
                    //   min-height: 140px;
                    // }
                    // @media (min-width: 1700px) {

                    // }
                  }

                  .video-control {
                    position: absolute;
                    border: unset;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #c00000;
                    border-radius: 50%;
                    padding: 3px 10px;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                    top: 35%;
                    left: 45%;

                    svg {
                      path {
                        fill: white;
                      }
                    }
                  }
                }

                .vidDeleteBtn {
                  background: #c00000;
                  border-radius: 50px;
                  width: 24px;
                  height: 24px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  position: absolute;
                  top: 5px;
                  right: 5px;

                  svg {
                    width: 15px;
                    height: 15px;

                    path {
                      fill: white;
                    }
                  }
                }
              }

              .vid-info-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0px 5px;

                .vid-info {
                  text-align: left;

                  a {
                    font-size: 11px;
                    line-height: 12px;
                    font-weight: 500;
                    color: #525252;
                    text-decoration: none;

                    &:hover,
                    &:active {
                      color: #c00000;
                    }

                    @media (min-width: 1400px) {
                      font-size: 11px;
                      line-height: 12px;
                    }

                    @media (min-width: 1600px) {
                      font-size: 12px;
                      line-height: 14px;
                    }

                    >div {
                      max-width: 92px;
                      width: 100%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;

                      @media (min-width: 1700px) {
                        max-width: 130px;
                      }
                    }
                  }
                }
              }
            }
          }

          .common-btn {
            padding: 5px 30px;
            position: absolute;
            bottom: -80px;
            // @media (min-width: 1900px) {
            //   padding: 7px 20px;
            // }
          }
        }

        .common-btn {
          background-color: transparent;
          border: 1px solid #acacac;
          color: #525252;
          padding: 6px 72px 6px 10px;
          display: block;
          box-shadow: 0px 4px 4px 0px #0000000d;

          &:hover,
          &:active {
            color: #c00000;
          }
        }
      }

      .form-select {
        font-size: 9px;
        line-height: 10px;
        padding: 4px 3px;

        @media (min-width: 1500px) {
          padding: 4px 8px;
        }

        // @media (min-width: 1900px) {
        //   padding: 7px 10px;
        // }
      }
    }
  }
}

.common-form {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #0000000d;
  padding: 20px;
}

// common select-option

.basic-multi-select {
  min-height: unset;

  >div {
    padding: 1px;
    border-radius: 10px;
  }

  span {
    display: none;
  }

  .select__control {
    box-shadow: 0px 4px 4px 0px #0000000d !important;
    padding: 0 10px;
    min-height: 40px;

    .mui-1fdsijx-ValueContainer {
      padding: 0;

      .mui-1dimb5e-singleValue,
      .select__input-container {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        // color: #acacac;
        color: #000000;
        margin: 0;
        padding: 0;
      }

      .select__placeholder {
        font-weight: 400;
        color: #525252;
      }
    }

    .select__indicators {
      .select__indicator {
        padding: 0 3px;
      }

      .select__indicator-separator {
        width: unset;
      }
    }
  }
}

// common select-option end
.modal-backdrop {
  background: rgba(51, 51, 51, 0.4);
}

.program-modal {
  .modal-dialog {
    max-width: 400px;

    .modal-header {
      border-bottom: none !important;
      display: flex;
      justify-content: center;
      align-items: center; /* Center vertically (if needed) */
      /* Center horizontally */
      width: 100%;
      /* Ensure full width */
     margin-left: 15px;

      .modal-title {
        flex-grow: 1;
        /* Ensure the title takes full space */
        text-align: center;
        /* Center text */
        font-size: 18px;
      }

    }

    .yes-btn {
      background-color: #C1341A;
      //padding: 10px 35px;
      border: 1px solid #C1341A;
      min-width: 110px;
      min-height: 50px;
    }

    .no-btn {
      background-color: #000;
      //padding: 10px 35px;
      border: 1px solid #000;
      min-width: 110px;
      min-height: 50px;

    }

    .btn-close {
      position: relative;
      opacity: 1;
      --bs-btn-close-bg: unset;

      &::before {
        content: "";
        background-image: url("../assets/images/icons/white-close.svg");
        background-repeat: no-repeat;
        background-color: #c00000;
        background-position: center;
        position: absolute;
        right: -20px;
        top: -38px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 9px;
      }
    }
  }

}

.common-modal {
  .modal-dialog {
    max-width: 700px;
    width: 100%;

    .modal-content {
      border-radius: 0;
      box-shadow: 1px 0px 40px 5px #5956e926;
      border-radius: 10px;
      border: none;
      border-color: #acacac;

      .modal-header {
        padding: 30px;

        .btn-close {
          position: relative;
          opacity: 1;
          --bs-btn-close-bg: unset;

          &::before {
            content: "";
            background-image: url("../assets/images/icons/white-close.svg");
            background-repeat: no-repeat;
            background-color: #c00000;
            background-position: center;
            position: absolute;
            right: -45px;
            top: -45px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            padding: 9px;
          }
        }

        .modal-title {
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          color: rgba(51, 51, 51, 1);
        }
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        padding: 30px 30px 40px;

        label {
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          margin-bottom: 8px;
        }

        input {
          // font-size: 14px;
          // line-height: 24px;
          // font-weight: 500;
          // color: #acacac;
          // border: 1px solid #acacac;
          // border-radius: 10px;
          // padding: 12px 24px;
          font-size: 14px;
          line-height: 14px;
          font-weight: 500;
          border-radius: 10px;
          padding: 9px 15px;
          height: 40px;

          &:focus {
            outline: none;
          }
        }

        .image-item {
          height: 255px;

          .image-item__btn-wrapper {
            button {
              left: unset;
              right: 10px;
            }
          }
        }

        .custom-file-upload {
          label {
            color: white;

            &:hover {
              color: #c00000;
            }
          }
        }

        small {
          color: #c00000;
        }
      }

      .modal-footer {
        padding: 30px 0;
        justify-content: center;

        .common-btn {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }
}

.custom-red {
  color: red !important;
}

.custom-blue {
  color: blue !important;
}

.VideoInput_video {
  width: 200px;
}

.testimonials-index {
  .image-item {
    margin-bottom: 20px;
    height: 255px;
  }

  textarea {
    padding: 10px;
  }
}

.vidContainer,
.image-item {
  position: relative;

  button {
    position: absolute;
    right: 10px;
    left: unset;
    top: 10px;
    padding: 5px;
    border-radius: 50% !important;
    display: flex;

    svg {
      width: 15px;
      height: 15px;
    }
  }

  video {
    height: 140px;
    position: relative;
    width: 100%;

    @media (min-width: 1700px) {
      height: 200px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// common loder
.spin-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75vh;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;
  border: none;
}

.loader-container.visible {
  opacity: 1;
  visibility: visible;
}

.blur-content {
  filter: blur(5px);
}

.common-title-block-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h3 {
    font-size: 22px;
    line-height: 24px;
    font-weight: 500;
    color: #333333;
    margin: 0px;
  }
}

// common loder end
// trash table
// .trash-data-index {
//   h1 {
//     margin-bottom: 0;
//   }

//   .MuiDataGrid-main {
//     .MuiDataGrid-columnHeaders {
//       max-height: 84px !important;

//       .MuiDataGrid-columnHeadersInner {
//         > div {
//           height: 40px;

//           .MuiDataGrid-columnHeader {
//             max-height: 40px;
//           }
//         }

//         .MuiDataGrid-headerFilterRow {
//           border-top: none;
//           height: 32px;

//           .MuiDataGrid-columnHeader {
//             height: 40px !important;
//           }

//           .MuiFormControl-root {
//             margin-bottom: 0;
//           }
//         }
//       }

//       .MuiDataGrid-pinnedColumnHeaders {
//         top: 9px;

//         .MuiDataGrid-columnHeader {
//           height: 40px !important;
//         }
//       }
//     }

//     .MuiDataGrid-virtualScroller {
//       .MuiDataGrid-virtualScrollerContent {
//         .MuiDataGrid-virtualScrollerRenderZone {
//           .MuiDataGrid-row {
//             min-height: 40px !important;

//             &:last-child {
//               border-bottom: none;
//             }

//             .MuiDataGrid-cell {
//               min-height: 40px !important;
//             }
//           }
//         }
//       }

//       .MuiDataGrid-pinnedColumns {
//         .MuiDataGrid-row {
//           min-height: 40px !important;

//           .MuiDataGrid-cell--withRenderer {
//             min-height: 40px !important;
//           }
//         }
//       }
//     }
//   }

//   .MuiDataGrid-footerContainer {
//     .MuiTablePagination-root {
//       .MuiToolbar-root {
//         p {
//           margin-bottom: 0;
//         }
//       }
//     }
//   }
// }

// // trash table end
// // custem sweet
// .base-Popper-root {
//   .MuiPaper-root {
//     .MuiDataGrid-panelWrapper {
//       .MuiDataGrid-panelContent {
//         .MuiDataGrid-columnsPanel {
//           .MuiButtonBase-root {
//             position: absolute;
//           }
//         }
//       }
//     }
//   }
// }

.MuiDataGrid-main {
  .MuiDataGrid-columnHeader {
    border: none !important;
    outline: none !important;
  }

  .MuiInputBase-root {
    border: 1px solid hsla(0, 0%, 67%, 0.502);
    border-radius: 4px;
    height: 30px !important;
    margin: 0;
    position: relative;
  }

  .MuiFormControl-root {
    .MuiInputLabel-root {
      display: none !important;
    }
  }
}

// trash table end
// custem sweet
.base-Popper-root {
  .MuiPaper-root {
    .MuiDataGrid-panelWrapper {
      .MuiDataGrid-panelContent {
        .MuiDataGrid-columnsPanel {
          .MuiButtonBase-root {
            position: absolute;
          }
        }
      }
    }
  }
}

.ck-editor__main {
  .ck-editor__editable {
    height: 150px;

    @media (min-width: 1700px) {
      height: 200px;
    }
  }
}

.new-file {
  .ck-editor__main {
    .ck-editor__editable {
      height: 550px;
    }
  }
}

.new-file {
  .ck-editor__editable {
    height: 150px;

    @media (min-width: 1700px) {
      height: 200px;
    }
  }
}

.MuiDataGrid-toolbarContainer {
  .MuiButtonBase-root {
    color: #333;
  }
}

// custem sweet end
.phone-input {
  padding: 1px 10px;
  border-radius: 10px;
  border: 1px solid #acacac !important;

  input {
    max-height: unset;
    border: none;
    padding: 0;
  }
}

// common-changes-password
// .pass-field {
//   input {
//     border-top-right-radius: 10px !important;
//     border-bottom-right-radius: 10px !important;
//   }

//   .btn-outline-secondary {
//     border: none;
//     position: absolute;
//     right: 7px;
//     top: 2px;
//     background-color: transparent !important;
//   }

//   .btn {

//     &:active,
//     &:focus,
//     &:hover {
//       color: #6c757d;
//     }
//   }
// }
.btn-outline-secondary {
  &:hover {
    background-color: #c00000 !important;
  }
}

.SportsModal,
.BodyTypesModal,
.InjuryModal {
  max-height: 600px;
  overflow-y: auto;

  h3 {
    padding: 10px;
    text-align: center;
    background: #000;
    font-size: 17px;
    color: #fff;
    font-weight: 600;
    margin: 0px;
  }

  table {
    border: 1px solid black;
    width: 100%;

    tbody {
      tr {
        td {
          padding: 5px;
          border: 1px solid black;
        }

        .right-side-value {
          text-align: right;
        }
      }
    }
  }
}

// common-changes-password end