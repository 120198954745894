.sidebar {
  background: rgba(51, 51, 51, 1);
  max-width: 300px;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  .nav {
    .nav-item {
      .nav-link {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: white;
        padding: 20px;
        position: relative;
        &:hover,
        &:focus,
        &:active {
          background-color: rgba(255, 255, 255, 0.9) !important;
          box-shadow: 0px 0px 40px 4px rgba(255, 255, 255, 0.2);
          color: #c00000 !important;
          transition: all 0.5s ease;
          &:before {
            content: "";
            background-image: url("../assets/images/icons/red-arrow.svg");
            right: 20px;
            top: 20px;
            transform: rotate(180deg);
          }
          svg {
            path {
              fill: #c00000;
            }
          }
        }
        svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;

          path {
            fill: white;
          }
        }
        &.active {
          --bs-nav-pills-link-active-bg: rgba(255, 255, 255, 0.9);
          color: #c00000;
          path {
            fill: #c00000;
          }

          &:before {
            content: "";
            background-image: url("../assets/images/icons/red-arrow.svg");
            background-repeat: no-repeat;
            display: block;
            width: 11px;
            height: 10px;
            position: absolute;
            right: 20px;
            top: 25px;
            transform: rotate(180deg);
          }
        }
      }
      .dropdown-toggle {
        &::after {
          display: none;
        }
        &:before {
          content: "";
          background-image: url("../assets/images/icons/down-drop-arrow.svg");
          background-repeat: no-repeat;
          display: block;
          width: 15px;
          height: 15px;
          position: absolute;
          right: 20px;
          top: 20px;
        }
      }
    }

    .show {
      .nav-link {
        --bs-nav-pills-link-active-bg: rgba(255, 255, 255, 0.9);
        color: #c00000;
        svg {
          path {
            fill: #c00000;
          }
        }
      }
      .dropdown-toggle {
        &::before {
          content: "";
          background-image: url("../assets/images/icons/red-arrow.svg");
          background-repeat: no-repeat;
          display: block;
          width: 11px;
          height: 10px;
          position: absolute;
          right: 20px;
          top: 25px !important;
          transform: unset !important;
        }
      }

      .show {
        .dropdown-toggle {
          position: relative;
          &::before {
            content: "";
            background-image: url("../assets/images/icons/red-arrow.svg");
            background-repeat: no-repeat;
            display: block;
            width: 11px;
            height: 10px;
            position: absolute;
            right: 20px;
            top: 25px !important;
            transform: unset !important;
          }
        }
        .nav-link {
          --bs-nav-pills-link-active-bg: rgba(255, 255, 255, 0.9);
          color: #c00000;
          svg {
            path {
              fill: #c00000;
            }
          }
        }
      }
    }
  }
}

// .navbar-expand-lg .navbar-toggler {
//   display: block;
//   border: none;
// }
// .sidebar::-webkit-scrollbar {
//   display: none;
// }
