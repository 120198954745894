.users-index {
  .custom-order-filters {
    .add-users-section {}

    // .common-table {
    //   .MuiDataGrid-toolbarContainer {
    //     margin-top: 8px;
    //   }
    //   .MuiDataGrid-main {
    //     margin-top: 0;
    //     .MuiDataGrid-columnHeaders {
    //       min-height: 120px !important;
    //       margin-top: 10px;
    //       .MuiDataGrid-pinnedColumnHeaders {
    //         top: 12px;
    //       }
    //       .MuiDataGrid-columnHeader {
    //         min-height: 45px !important;
    //       }
    //       .MuiDataGrid-headerFilterRow {
    //         min-height: 40px;
    //       }
    //     }
    //   }
    // }
  }

  // .custom-file-upload {

  // }

  .add-new-user {
    .form-select {
      margin-bottom: 0;

    }

    .image-item {
      max-width: 350px;
      width: 100%;
      height: 170px;
      border: 1px solid #acacac;
      padding: 4px;
      margin: 0 0 10px 5px;

      @media (min-width: 1700px) {
        height: 255px;
      }


    }

    .common-btn {
      cursor: pointer;
    }
  }

  .common-header-block {
    .customOrderFilters {
      .all-user {
        margin-bottom: 15px;

        .search-bar {
          .common-search-bar {
            .input-group {
              .input-group-text {
                width: 40px;
                height: 40px;
                padding: 8px;

                svg {
                  width: 20px;
                }
              }

              .form-control {
                font-size: 13px;
                height: 40px;

                &::placeholder {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }

  .common-table {
    .all-user {
      margin-bottom: 10px;
    }
  }

  .user-pagination {
    .MuiPagination-root {
      ul {
        display: flex;
        justify-content: center;

        li {
          .MuiButtonBase-root {
            background: #acacac66;
          }

          .Mui-selected {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: white;
            background-color: #c00000;
          }
        }
      }
    }
  }
}