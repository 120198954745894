.profile-index {
  h1 {
    margin: 20px 0;
  }
  .add-profile-section {
    background-color: white;
    box-shadow: 0px 4px 4px 0px #0000000d;
    padding: 20px;
    border-radius: 10px;
    .profile-card {
      .profile-details {
        h4 {
          font-size: 20px;
          font-weight: 500;
          line-height: 20px;
          color: #333333;
          margin-bottom: 20px;
        }
        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          color: #acacac;
        }
      }
      .profile-img {
        width: 140px;
        height: 140px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }
    .floating-link {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #c00;
      text-decoration: none;
    }
  }
}
